import {CONST} from "@/utils/Constants";
import axios from 'axios/index';

export class ApiHelper {
    static get(url, options = {params: null, headerType: null}) {
        return axios.get(ApiHelper.getUrlPrefix() + url, {
            headers: ApiHelper.getHeadersByOptions(options),
            params: options.params
        });
    }

    static getJSONFile(url, options = {params: null}) {
        return axios.get(url, {
            headers: ApiHelper.getHeadersByOptions(options),
            params: options.params
        });
    }

    static getUrlPrefix() {
        // return process.env.VUE_APP_API_BASE_URL
        return CONST.API.BASE_URL
      }

    static getHeadersByOptions(options = {headersType: null}) {
        return (options.headersType === 'basic') ? ApiHelper.getBasicHeaders() : ApiHelper.getHeaders();
    }

    static getBasicHeaders(contentType) {
        let headers = {};
        headers[CONST.KEY.REQUEST.ACCEPT] = CONST.VALUE.REQUEST.ACCEPT_JSON;
        headers[CONST.KEY.REQUEST.CONTENT_TYPE] = contentType;

        return headers;
    }

    static getHeaders() {
        let headers = ApiHelper.getBasicHeaders(CONST.VALUE.REQUEST.ACCEPT_JSON);

        return headers;
    }

    static parseUrlWithParameters(url, params) {
        if (params)
            for (let i = 0; i < params.length; i++)
                for (let key in params[i])
                    url = url.replace(':' + key, params[i][key]);

        return url;
    }
}

export default ApiHelper;
