/* eslint-disable no-console */
import { mapState } from 'vuex';
import mNavigation from '@/modules/m-navigation/m-navigation.vue';
import {CONST} from "@/utils/Constants";
import ApiHelper from '@/helpers/ApiHelper';

export default {
  name: 'display',
  components: {
    'm-navigation': mNavigation
  },
  data() {
    return {
    };
  },
  methods: {
    fetchData(locale) {
      ApiHelper
            .get(
              CONST.API.ROUTE.DISPLAY + locale
            )
            .then((res) => {
              this.$store.commit('updateContent', { data: res.data.data, locale })
            })
    },
    setZoneId() {
      if(!this.$store.state.zoneIdInit) {
        this.$store.state.zoneIdInit = this.$route.params.zoneid;
      }
      this.$store.state.zoneId = this.$route.params.zoneid;
      document.querySelector('body').classList.remove('zone-1', 'zone-2', 'zone-3', 'zone-4', 'zone-5', 'zone-6');
      document.body.classList.add(`zone-${this.$store.state.zoneId}`);
    }
  },
  mounted() {
    this.fetchData('nl');
    this.fetchData('de');
    this.fetchData('en');
    this.setZoneId();
  },
  watch: {
    isIdle: function(i) {
      if(i) {
        this.$router.replace('/'+this.$store.state.zoneIdInit);
      }
    }
  },
  computed: {
    isIdle() {
      return this.isAppIdle;
    },
    ...mapState(['zoneId', 'idle', 'content'])
  },
  updated() {
    this.setZoneId();
  }
};
