import { render, staticRenderFns } from "./o-navigation-item.vue?vue&type=template&id=033abd48&scoped=true"
import script from "./o-navigation-item.js?vue&type=script&lang=js&external"
export * from "./o-navigation-item.js?vue&type=script&lang=js&external"
import style0 from "./o-navigation-item.scss?vue&type=style&index=0&id=033abd48&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "033abd48",
  null
  
)

export default component.exports