import { mapState } from 'vuex';
import oIcon from '../o-icon/o-icon.vue';

export default {
  name: 'oNavigationItem',
  components: {
    'o-icon': oIcon
  },
  props: {
    icon: String,
    zone_id: String,
    title: String,
    to: String
  },
  data() {
    return {
    };
  },
  methods: {
    getClass() {
      if (this.zone_id === this.$store.state.zoneId) {
        return `o-navigation-zone-${this.zone_id} router-link-active`;
      }
      return `o-navigation-zone-${this.zone_id}`;
    }
  },
  computed: {
    ...mapState(['zoneId'])
  }
};
