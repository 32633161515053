import { mapState } from 'vuex';
import oNavigationItem from '../../objects/o-navigation-item/o-navigation-item.vue';

export default {
  name: 'mNavigation',
  components: {
    'o-navigation-item': oNavigationItem
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    ...mapState(['zoneId'])
  }
};
